<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/banner`"
                title="Banner"
                :columns="columns"
                :formOptions="formOptions"
                routerpath="/setup/addbanner"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from '../../../components/GoodTableColumnSearch.vue'
import moment from 'moment'
const baseApi = process.env.VUE_APP_APIENDPOINT
import axios from '@/components/axios'

export default {
  components: {
    GoodTableColumnSearch
  },
  data () {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        {
          label: 'Location',
          field: 'position',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Location'
          }
        },
        {
          label: 'Action',
          field: 'action'
        }
      ],
      formOptions: {
        formTitle: `${this.$route.params.id ? 'Edit Banner' : 'Add Banner'}`,
        submitRouterPath: '/setup/Banner',
        usersDropdown: true,
        getEditValue: `${baseApi}/getBannerById`,
        gridForm: true,
        method: 'post',
        action: 'add',
        url: `${baseApi}/banner`,
        inputFields: [
          {
            name: 'branch',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'active',
            value: 1,
            type: 'hidden'
          },
          {
            label: 'Name',
            name: 'name',
            value: '',
            type: 'text',
            placeholder: 'Enter Name',
            class: 'col-md-12',
            required: true
          },

          {
            label: 'Software Name',
            name: 'softwarename',
            value: '',
            type: 'dropdown',
            class: 'col-md-12',
            url: 'getsoftwares',
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: []
          },
          {
            label: 'Software Name',
            name: 'softwarename',
            value: '',
            type: 'dropdown',
            class: 'col-md-12',
            url: 'getsoftwares',
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: []
          },
          {
            label: 'Software Name',
            name: 'softwarename',
            value: '',
            type: 'dropdown',
            class: 'col-md-12',
            url: 'getsoftwares',
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: []
          },
          {
            label: 'Software Name',
            name: 'softwarename',
            value: '',
            type: 'dropdown',
            class: 'col-md-12',
            url: 'getsoftwares',
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: []
          },
          {
            label: 'Software Name',
            name: 'softwarename',
            value: '',
            type: 'dropdown',
            class: 'col-md-12',
            url: 'getsoftwares',
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: []
          },
          {
            label: 'Software Name',
            name: 'softwarename',
            value: '',
            type: 'dropdown',
            class: 'col-md-12',
            url: 'getsoftwares',
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: []
          },
          {
            label: 'Software Name',
            name: 'softwarename',
            value: '',
            type: 'dropdown',
            class: 'col-md-12',
            url: 'getsoftwares',
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: []
          },
          {
            label: 'Software Name',
            name: 'softwarename',
            value: '',
            type: 'dropdown',
            class: 'col-md-12',
            url: 'getsoftwares',
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: []
          },
          {
            label: 'Software Name',
            name: 'softwarename',
            value: '',
            type: 'dropdown',
            class: 'col-md-12',
            url: 'getsoftwares',
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: []
          },
          {
            label: 'Software Name',
            name: 'softwarename',
            value: '',
            type: 'dropdown',
            class: 'col-md-12',
            url: 'getsoftwares',
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: []
          }
        ]
      }
    }
  },
  mounted () {
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      this.$router.push('/')
    }
    // this.getDropdowns()
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
